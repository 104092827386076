import * as React from 'react';
import { useState, useEffect } from 'react';
import { AutoComplete, Input } from 'antd';

import { loadBiomarkers, Biomarker } from './data';


const mockVal = (str: string, repeat: number = 1) => ({
  value: str.repeat(repeat),
});

interface BiomarkerInputProps {
  onPick: (v: Biomarker) => void;
}
  
  
export const BiomarkerInput: React.FC<BiomarkerInputProps> = ({onPick}) => {
  const [value, setValue] = useState('');
  
  const [biomarkers, setBiomarkers] = useState({});
  useEffect(() => {
    (async () => {
      setBiomarkers(await loadBiomarkers());
    })();
  }, [])
  
  const options = [
    ...Object.values(biomarkers).sort((a, b) => {
      const at = a.title.toUpperCase();
      const bt = b.title.toUpperCase();
      return at == bt ? 0  : (at > bt ? 1 : -1);
    }).map((b: Biomarker) => ({
      label: b.title,
      value: b.handle,
    }))
  ];
  
  const onSelect = (data: string) => {
    const biomarker = biomarkers[data];
    console.log({data});
    if (!biomarker) {
      return;
    }
    
    onPick(biomarker);
    setValue('');
  };
  const onChange = (data: string) => {
    setValue(data);
  };
  
  const onSearch = (data: string) => {
    console.log({onsearch: data});
  };
  const handleEnter = (data: string) => {
    console.log({handleenter: data});
  };
  
  return (
    <AutoComplete
      placeholder="Enter biomarker..."
      value={value}
      options={options}
      filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      style={{ width: '100%' }}
      onSelect={onSelect}
      onChange={onChange}
      onSearch={onSearch}
      defaultActiveFirstOption={true}
    />
  );
};
