import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { List, Card, Descriptions, Space, Row, Col, Tag, Typography, Button, Carousel } from 'antd';

import { biomarkers, Biomarker, Product, Result, CollectionMethod, BiomarkerDescription } from './data';

const { Text } = Typography;

function formatPrice(price: number): string {
    return '£' + (price / 100).toFixed(2);
}

function getDesiredBiomarkersFromProduct(product: Product, desiredBiomarkers: Biomarker[]): Biomarker[] {
    return desiredBiomarkers.filter(b => product.biomarkerKeys.indexOf(b.handle) > -1);
}


const Centered = styled.div`
    text-align: center;
    padding: 10px;
`;
const ProductLink = styled.a`
    display: block;
`;

interface ResultsTableProps {
    biomarkers: Biomarker[];
    results: Result[];
    cheapest: Result[];
    carousel?: boolean;
}
export const ResultsTable: React.FC<ResultsTableProps> = ({ biomarkers, results, cheapest, carousel }) => {
    const orderedResults = results.sort((a, b) => {
        // First: full coverage results
        if (a.fullCoverage && !b.fullCoverage) {
            return -1;
        }
        if (!a.fullCoverage && b.fullCoverage) {
            return 1;
        }
        
        // Next: partial coverage
        if (a.coverage > b.coverage) {
            return -1;
        }
        if (b.coverage > a.coverage) {
            return 1;
        }
        
        // Next: cheapest
        if (a.totalPrice > b.totalPrice) {
            return 1;
        }
        if (b.totalPrice > a.totalPrice) {
            return -1;
        }
        
        // Next: most biomarkers
        if (a.allBiomarkers.length > b.allBiomarkers.length) {
            return -1;
        }
        if (b.allBiomarkers.length > a.allBiomarkers.length) {
            return 1;
        }
        
        
        return 0;
    });
    
    const [limit, setLimit] = useState(50);
    
    const ShowMore: React.FC = () => {
        if (orderedResults.length <= limit) {
            return (
                <></>
            );
        }
        return (
            <>
                <p>Showing first {limit} results...</p>
                <Button onClick={() => setLimit(limit + 50)}>
                    Show more
                </Button>
                <div style={{paddingBottom: '100px'}}></div>
            </>
        );
    };
    
    const Wrapper: React.FC = ({children}) => {
        if (carousel) {
            return <Carousel dots={{className: 'ResultsTable__Dots'}}>{children}</Carousel>
        }
        return <div>{children}</div>
    };
    
    return (
        <>
            <Wrapper>
                {orderedResults.slice(0,limit).map(result => (
                    <Row 
                        key={result.key}
                        style={{marginBottom: '20px'}}
                    >
                        <Col xs={24} style={{paddingBottom: '20px'}}>
                            <Card 
                                title={(
                                    <>
                                        <Text style={{color: 'white'}}>
                                            {formatPrice(result.products.reduce((acc, p) => acc + p.price, 0))}
                                        </Text>
                                        <Text style={{color: 'white'}}>
                                            {` - ${result.products.length} test${result.products.length > 1 ? 's' : ''}` + ` - ${result.products.map(p => p.title).join(' + ')}`}
                                        </Text>
                                    </>
                                )}
                                bodyStyle={{padding: 0}}
                                headStyle={{backgroundColor: (cheapest[0]?.totalPrice || 0) == result.totalPrice ? '#52c419' : '#001529'}}
                            >
                                <Descriptions 
                                    size="small"
                                    bordered
                                    column={1}
                                >
                                    <Descriptions.Item label="Products">
                                        {result.products.map(p => (
                                            <ProductLink 
                                                href={`https://medichecks.com/products/${p.handle}`}
                                                target="_blank"
                                            >
                                                <Space wrap>
                                                    <Text style={{color: '#1890ff'}}>
                                                        {p.title}
                                                    </Text>
                                                    <Tag>
                                                        <Text type="danger">
                                                            {formatPrice(p.price)}
                                                        </Text>
                                                    </Tag>
                                                    {getDesiredBiomarkersFromProduct(p, biomarkers).map(biomarker => (
                                                        <Tag key={biomarker.handle} color="green">
                                                            {biomarker.title}
                                                        </Tag>
                                                    ))}
                                                </Space>
                                            </ProductLink>
                                        ))}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Collection methods">
                                        <Space wrap>
                                            {result.collectionDescriptions.map(method => (
                                                <Tag>
                                                    {method.price > 0 ? (
                                                        <Text type="danger">
                                                            {formatPrice(method.price)}
                                                        </Text>
                                                    ):(
                                                        <Text type="success">
                                                            FREE
                                                        </Text>
                                                    )}
                                                    &nbsp;-&nbsp;  
                                                    {method.label}
                                                </Tag>
                                            ))}
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions
                                    size="small"
                                    bordered
                                    layout="vertical"
                                >
                                    <Descriptions.Item label="Biomarkers">
                                        <Space wrap size={2}>
                                            {result.biomarkerDescriptions.map(biomarkerDesc => (
                                                <Tag 
                                                    color={biomarkerDesc.missing ? 'red' : (biomarkerDesc.desired ? 'green' : 'gray')}
                                                >
                                                    {biomarkerDesc.biomarker.title}
                                                </Tag>
                                            ))}
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                    </Row>
                ))}
            </Wrapper>
            <ShowMore/>
        </>
    );
};