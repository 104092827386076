import axios from 'axios';

export interface Biomarker {
    title: string;
    handle: string;
    description: string;
}

export type SampleType = 'Blood'|'Urine'|'Stool';
export type CollectionMethodType = 'FINGER_PRICK'|'CLINIC'|'HOME_VISIT'|'OWN_ARRANGEMENTS'|'LONDON_CLINIC';
export interface CollectionMethod {
    type: CollectionMethodType;
    price: number;
    description: string;
}
export interface Product {
    title: string;
    handle: string;
    variantId: number;
    displayName: string;
    price: number;
    description: string;
    biomarkerKeys: string[];
    turnaround: number|null;
    collectionMethods: CollectionMethod[];
}

export interface BiomarkerDescription {
    biomarker: Biomarker;
    desired: boolean;
    missing: boolean;
}
export interface CollectionDescription {
    label: string;
    price: number;
}
export interface Result {
    key: string;
    products: Product[];
    totalPrice: number;
    fullCoverage: boolean;
    coverage: number;
    allBiomarkers: Biomarker[]
    biomarkerDescriptions: BiomarkerDescription[];
    collectionDescriptions: CollectionDescription[];
}


const biomarkerPromise = new Promise<Record<string, Biomarker>>(async (res, rej) => {
    try {
        const { data } = await axios.get('/biomarkers.json', {
            headers: {
                'x-test': 'abc,'
            }
        });
        const biomarkersRaw = data as Biomarker[];
        const biomarkers: Record<string, Biomarker> = {};
        for (let bm of biomarkersRaw) {
            if (bm.handle == 'biomarkers-red-blood-cells') {
                continue;
            }
            biomarkers[bm.handle] = bm;
        }
        res(biomarkers);
    } catch (e) {
        rej(e);
    }
});
export const loadBiomarkers = () => {
    return biomarkerPromise;
};


const productPromise = new Promise<Product[]>(async (res, rej) => {
    try {
        const { data } = await axios.get('/products.json', {
            headers: {
                'x-test': 'abc,'
            }
        });
        const p = (data as Product[]).filter(p => {
            if (p.handle == 'vitamin-d-25-oh') {
                return false;
            }
            return true;
        }).map(p => {
            return p;
        });
        res(p);
    } catch (e) {
        rej(e);
    }
})
export const loadProducts = () => {
    return productPromise;
};
