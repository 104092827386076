import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { Button, Card, Layout, Typography, Row, Col, Input, Tag, Space, Result as AntResult, Divider, Checkbox, Spin, Form, Select, Alert } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import './App.css';

import { loadBiomarkers, loadProducts, Biomarker, Product, Result, CollectionDescription, BiomarkerDescription } from './data';
import { BiomarkerInput } from './BiomarkerInput';
import { ResultsTable } from './ResultsTable';


const { Title, Paragraph, Text, Link } = Typography;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;


let preselectedBiomarkers: Biomarker[] = [
];
let preloadFingerprickOnly: boolean = false;
let preloadIgnoreLondon: boolean = true;
let preloadMaxTests: number = 4;
let hashProcessed = false;
async function preloadTests() {
  console.log('preloading now');
  const hash =  window.location.hash;
  hashProcessed = true;
  if (hash) {
  const biomarkers = await loadBiomarkers();
    try {
      const data = JSON.parse(atob(hash.slice(1)));
      if (data) {
        preselectedBiomarkers = [];
        for (let handle of data.biomarkerHandles) {
          const biomarker = biomarkers[handle];
          if (biomarker) {
            preselectedBiomarkers.push(biomarker);
          }
        }
        preloadFingerprickOnly = data.fingerprickOnly;
        preloadIgnoreLondon = data.ignoreLondon;
        preloadMaxTests = data.maxTests;
      }
      return true;
    } catch (e) {
      console.error('Could not load from hash', e);
    }
  }
  return false;
}

function getBiomarkerDescriptions(allBiomarkers: Biomarker[], desiredBiomarkers: Biomarker[]): BiomarkerDescription[] {
    const r: BiomarkerDescription[] = [];
    for (let biomarker of allBiomarkers) {
        // TODO
        r.push({
            biomarker,
            desired: desiredBiomarkers.indexOf(biomarker) > -1,
            missing: false,
        })
    }
    for (let biomarker of desiredBiomarkers) {
        if (allBiomarkers.indexOf(biomarker) === -1) {
            r.push({
                biomarker,
                desired: true,
                missing: true,
            });
        }
    }
    
    return r.sort((a, b) => {
        if (a.desired && !b.desired) {
            return -1;
        }
        if (!a.desired && b.desired) {
            return 1;
        }
        if (a.missing && !b.missing) {
            return 1;
        }
        if (!a.missing && b.missing) {
            return -1;
        }
        
        const at = a.biomarker.title.toUpperCase();
        const bt = b.biomarker.title.toUpperCase();
        return at === bt ? 0 : (at > bt ? 1 : -1);
    });
}

function createResult(products, desiredBiomarkers: Biomarker[], fullCoverage: boolean, biomarkers: Record<string, Biomarker>): Result {
  let allBiomarkers: Biomarker[] = [];
  for (let product of products) {
    console.log({product})
      for (let handle of product.biomarkerKeys) {
          const biomarker = biomarkers[handle];
          if (!biomarker) {
              continue;
          }
          allBiomarkers.push(biomarker);
      }
  }
  
  allBiomarkers = _.uniq(allBiomarkers);
  
  
  const biomarkerDescriptions = getBiomarkerDescriptions(allBiomarkers, desiredBiomarkers);
  const collectionDescriptions = getCollectionMethods(products);
  
  
  const key = _.uniqueId('result_');
  
    
  return {
    key,
    products,
    totalPrice: products.reduce((acc, p) => acc + p.price, 0),
    allBiomarkers,
    biomarkerDescriptions,
    collectionDescriptions,
    fullCoverage,
    coverage: biomarkerDescriptions.filter(bd => bd.desired && !bd.missing).length,
  };
}

function getCollectionMethods(products: Product[]): CollectionDescription[] {
    let allowFingerPrick = true;
    let allowVenous = true;
    let londonOnly = false;

    for (let product of products) {
        if (product.collectionMethods.filter((cm) => cm.type == 'LONDON_CLINIC').length > 0) {
            londonOnly = true;
            break;
        }
        if (product.collectionMethods.filter((cm) => cm.type == 'FINGER_PRICK').length < 1) {
            allowFingerPrick = false;
        }
    }
    // TODO: london clinics

    if (londonOnly) {
        return [
            {
                label: 'Visit The Doctors Laboratory clinic in London',
                price: 3000,
            }
        ];
    }

    const r: CollectionDescription[] = [];
    if (allowFingerPrick) {
        r.push({
            label: 'Finger prick kit',
            price: 0,
        });
    }
    if (allowVenous) {
        r.push({
            label: 'Visit partner clinic',
            price: 3000,
        });
        r.push({
            label: 'Nurse home visit',
            price: 5500,
        });
    }
    r.push({
        label: 'Make own arrangements',
        price: 0,
    });

    return r;
}


async function generateResults(products: Product[], biomarkers: Biomarker[], reportProgress: (p: number) => void, maxTests: number, allBiomarkers: Record<string, Biomarker>): Promise<Result[]> {
  
  const r: Result[] = [];
  
  const fullCoverageProducts: Product[] = [];
  const partialCoverageProducts: Product[] = [];
  
  for (let product of products) {
    let full = true;
    let partial = false;
    for (let biomarker of biomarkers) {
      if (product.biomarkerKeys.indexOf(biomarker.handle) !== -1) {
        partial = true;
      } else {
        full = false;
      }
    }
    
    if (full) {
      fullCoverageProducts.push(product);
    } else if (partial) {
      partialCoverageProducts.push(product);
    }
  }
  
  // Full coverage
  for (let product of fullCoverageProducts) {
    r.push(createResult([product], biomarkers, true, allBiomarkers));
  }
  
  const desiredBiomarkerHandles = biomarkers.map(b => b.handle);
  function desiredProductBiomarkers(product: Product): string[] {
    return product.biomarkerKeys.filter(handle => desiredBiomarkerHandles.indexOf(handle) > -1);
  }
  
  // TODO: partial combinations
  const combinations = await getCombinations2(partialCoverageProducts, biomarkers, reportProgress, maxTests);
  console.log({combinationCount: combinations.length});
  for (let products of combinations) {
    const uniqueBiomarkers = _.uniq(_.flatMap(products, desiredProductBiomarkers));
    r.push(createResult(products, biomarkers, biomarkers.length === uniqueBiomarkers.length, allBiomarkers));
  }
  
  // Partial coverage
  for (let product of partialCoverageProducts) {
    r.push(createResult([product], biomarkers, false, allBiomarkers));
  }
  
  return r;
}

/*
async function getCombinations(products: Product[], desiredBiomarkers: Biomarker[], reportProgress: (p: number) => void): Promise<Product[][]> {
  const desiredBiomarkerHandles = desiredBiomarkers.map(b => b.handle);
  function desiredProductBiomarkers(product: Product): string[] {
    return product.biomarkerKeys.filter(handle => desiredBiomarkerHandles.indexOf(handle) > -1);
  }
  
  const productsByBiomarker: Record<string, Product[]> = {};
  for (let product of products) {
    const handles = desiredProductBiomarkers(product);
    for (let handle of handles) {
      if (!productsByBiomarker[handle]) {
        productsByBiomarker[handle] = [];
      }
      productsByBiomarker[handle].push(product);
    }
  }
  for (let biomarkerHandle of Object.keys(productsByBiomarker)) {
    productsByBiomarker[biomarkerHandle] = productsByBiomarker[biomarkerHandle].sort((a, b) =>{
      return a.price == b.price ? 0 : (a.price > b.price ? 1 : -1);
    });
  }
  
  const productsWithCoverage: {coverage: number, product: Product}[] = products.map(p => ({
    product: p,
    coverage: _.intersect(desiredBiomarkerHandles, p.biomarkerKeys).length,
  }));
  
  const maxInspections = 1000;
  let inspections = 0;
  
  const r = [];
  for (let {product} of productsWithCoverage) {
    inspections++;
    if (inspections > maxInspections) {
      break;
    }
    
    const missing = _.difference(desiredBiomarkerHandles, product.biomarkerKeys);
    if (missing.length < 1) {
      r.push([product]);
      continue;
    }
    
    const covered = _.intersect(desiredBiomarkerHandles, product.biomarkerKeys);
    const items = [product];
    
    for (let biomarkerHandle of missing)
      for (let other of productsByBiomarker[biomarkerHandle]) {
        covered
      }
    }
  }
  
}
*/

function* cartesian(head, ...tail) {
  // @ts-ignore
  const remainder = tail.length > 0 ? cartesian(...tail) : [[]];
  for (let r of remainder) for (let h of head) yield [h, ...r];
}
function powerset(arr, len, pref=[]) {
    if (len == 0) return [pref];
    if (len > arr.length) return [];
    if (len == arr.length) return [pref.concat(arr)]; // premature optimisation
    const next = arr.slice(1);

    // @ts-ignore
    return powerset(next, len-1, [...pref, arr[0]]).concat(powerset(next, len, pref));
}
function cartesian2(arg) {
    var r = [], max = arg.length-1;
    function helper(arr, i) {
        for (var j=0, l=arg[i].length; j<l; j++) {
            var a = arr.slice(0); // clone arr
            a.push(arg[i][j]);
            if (i==max)
    // @ts-ignore
                r.push(a);
            else
                helper(a, i+1);
        }
    }
    helper([], 0);
    return r;
}

async function getCombinations2(products: Product[], desiredBiomarkers: Biomarker[], reportProgress: (p: number) => void, maxTests: number): Promise<Product[][]> {
  const desiredBiomarkerHandles = desiredBiomarkers.map(b => b.handle);
  function desiredProductBiomarkers(product: Product): string[] {
    return product.biomarkerKeys.filter(handle => desiredBiomarkerHandles.indexOf(handle) > -1);
  }
  const biomarkerFlags: Record<string, number> = {};
  let allFlags = 0;
  let i = 1;
  for (let handle of desiredBiomarkerHandles) {
    biomarkerFlags[handle] = i;
    allFlags |= i;
    i *= 2;
  }
  
  interface ProductEntry {
    product: Product;
    flags: number;
  }
  
  const entries: ProductEntry[] = products.map(p => ({
    product: p,
    flags: desiredProductBiomarkers(p).reduce((acc, handle) => acc + biomarkerFlags[handle], 0),
  }));
  
  function filterMatching(entrySets: ProductEntry[][]): ProductEntry[][] {
    return entrySets.filter(entries => {
      const flags = entries.reduce((acc, entry) => acc + entry.flags, 0);
      return flags == allFlags;
    });
  }
  
  const seen: Record<string, boolean> = {};
  
  const permutations: ProductEntry[][] = [];
  for (let i = 2; i <= maxTests; i++) {
    const rawSet = powerset(Object.values(entries), i);
    const matchingSet = filterMatching(rawSet);
    
    let j = 0;
    for (let pe of matchingSet) {
      let key = pe.map(p => p.product.handle).sort().join('~');
      if (seen[key]) {
    // @ts-ignore
        return;
      }
      j++;
      seen[key] = true;
      
      permutations.push(pe);
    }
    console.log(`Checking permutation of ${i} tests: ${rawSet.length} raw / ${matchingSet.length} matching / ${j} unique`);
  }
    
  return permutations.map(pes => {
    return pes.map(pe => pe.product);
  })
}


async function getCombinations(products: Product[], desiredBiomarkers: Biomarker[], reportProgress: (p: number) => void): Promise<Product[][]> {
  const desiredBiomarkerHandles = desiredBiomarkers.map(b => b.handle);
  function desiredProductBiomarkers(product: Product): string[] {
    return product.biomarkerKeys.filter(handle => desiredBiomarkerHandles.indexOf(handle) > -1);
  }
  
  const productsByBiomarker: Record<string, Product[]> = {};
  for (let product of products) {
    const handles = desiredProductBiomarkers(product);
    for (let handle of handles) {
      if (!productsByBiomarker[handle]) {
        productsByBiomarker[handle] = [];
      }
      productsByBiomarker[handle].push(product);
    }
  }
  
  for (let biomarkerHandle of Object.keys(productsByBiomarker)) {
    productsByBiomarker[biomarkerHandle] = productsByBiomarker[biomarkerHandle].sort((a, b) =>{
      return a.price == b.price ? 0 : (a.price > b.price ? 1 : -1);
    });
  }
  
  const totalCombinations = Object.values(productsByBiomarker).reduce((acc, ps) => acc * ps.length, 1);
  console.log({totalCombinations, productsByBiomarker});
  
  const seen: string[] = [];
  let combinationsIter = 0;
  
  const maxTests = 3;
  
  const uniqueCombinations: Product[][] = [];
  // @ts-ignore
  for (let ps of cartesian(...Object.values(productsByBiomarker))) {
    const products: Product[] = ps;
    let unique = _.uniq(products);
    if (unique.length > maxTests) {
      continue;
    }
    
    const key = unique.map(p => p.handle).join('~');
    if (seen.indexOf(key) > -1) {
      continue;
    }
    seen.push(key);
    
    uniqueCombinations.push(unique);
  }
    
  const r: Product[][] = [];
  for (let unique of uniqueCombinations) {
    combinationsIter++;
    if ((combinationsIter % (uniqueCombinations.length / 100)) == 0) {
      const progress = (combinationsIter / uniqueCombinations.length) * 100;
      console.log(progress);
      reportProgress(progress);
      await new Promise(res => setTimeout(res, 0));
    }
    
    const biomarkers = products.map(desiredProductBiomarkers);
    
    for (let i in biomarkers) {
      const biomarkerSet = biomarkers[i];
      const others = biomarkers.filter(bs => bs != biomarkerSet);
      const otherMarkers = _.uniq(_.flatten(others));
      
      if (otherMarkers.length == desiredBiomarkers.length) {
        // contains redundant product
        continue;
        //unique.splice(Number(i), 1);
        //return;
      }
    }
    
    
    r.push(unique);
  }
  return r;
}


const MainTitle = styled(Text)`
  color: white !important;
  font-size: 20px !important;
`;
const Centered = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
`;
  
const App: React.FC = () => {
  const [activeBiomarkers, setActiveBiomarkers] = useState<Biomarker[]>(preselectedBiomarkers);
  
  
  const onPickBiomarker = (b: Biomarker) => {
    setActiveBiomarkers(_.uniq([
      ...activeBiomarkers,
      b,
    ].sort((a, b) => {
      const at = a.title.toUpperCase();
      const bt = b.title.toUpperCase();
      return at == bt ? 0 : (at > bt ? 1 : -1);
    })));
  };
  
  const onRemoveBiomarker = (b: Biomarker) => {
    setActiveBiomarkers(activeBiomarkers.filter(b2 => b2 != b));
    return false;
  };
  
  const [fingerprickOnly, setFingerprickOnly] = useState<boolean>(preloadFingerprickOnly);
  const [ignoreLondon, setIgnoreLondon] = useState<boolean>(preloadIgnoreLondon);
  const [maxTests, setMaxTests] = useState<number>(preloadMaxTests);
  
  useEffect(() => {
    if (hashProcessed) {
      console.log('hash processed, updating');
      window.location.hash = btoa(JSON.stringify({
        biomarkerHandles: activeBiomarkers.map(b => b.handle),
        fingerprickOnly,
        ignoreLondon,
        maxTests,
      }));
    }
  }, [hashProcessed, activeBiomarkers, fingerprickOnly, ignoreLondon, maxTests]);
  
  
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [results, setResults] = useState<Result[]>([]);
  const [cheapest, setCheapest] = useState<Result[]>([]);
  const [mostMarkers, setMostMarkers] = useState<Result|null>(null);
  
  const reportProgress = (p: number) => {
    setProgress(p);
  };
  
  const [biomarkers, setBiomarkers] = useState<Record<string, Biomarker>>({});
  const [products, setProducts] = useState<Product[]>([]);
  useEffect(() => {
    (async () => {
      setBiomarkers(await loadBiomarkers());
      setProducts(await loadProducts());
      console.log('preloading');
      if (await preloadTests()) {
        setActiveBiomarkers(preselectedBiomarkers);
        setFingerprickOnly(preloadFingerprickOnly);
        setIgnoreLondon(preloadIgnoreLondon);
        setMaxTests(preloadMaxTests);
      }
    })();
  }, [])
  
    useEffect(() => {
        setResults([]);
        setCheapest([]);
        setMostMarkers(null);
        setLoading(true);
        setProgress(0);
        const handle = setTimeout(load, 100);
        return () => {
            clearTimeout(handle);
        };

        async function load() {
            console.log('LOADING');
            const relevantProducts = products.filter(p => {
                return (p.collectionMethods.map((cm) => cm.type).indexOf('FINGER_PRICK') > -1) ||
                    (p.collectionMethods.map((cm) => cm.type).indexOf('CLINIC') > -1);
            }).filter(p => {
                return !fingerprickOnly || (p.collectionMethods.map((cm) => cm.type).indexOf('FINGER_PRICK') > -1);
            }).filter(p => {
                return !ignoreLondon || (p.collectionMethods.map((cm) => cm.type).indexOf('LONDON_CLINIC') < 1);
            });

            const results = await generateResults(relevantProducts, activeBiomarkers, reportProgress, maxTests, biomarkers);

            let cheapest: Result[] = [];
            let mostMarkers: Result | null = null;

            for (let result of results) {
                if (result.fullCoverage && (cheapest.length == 0 || cheapest[0].totalPrice > result.totalPrice)) {
                    cheapest = [result];
                } else if (result.fullCoverage && cheapest[0].totalPrice == result.totalPrice) {
                    cheapest.push(result);
                }
                if (!mostMarkers || (mostMarkers.allBiomarkers.length < result.allBiomarkers.length && mostMarkers.products.length >= result.products.length)) {
                    if (result.fullCoverage) {
                        mostMarkers = result;
                    }
                }
            }

            setResults(results);
            setCheapest(cheapest);
            setMostMarkers(mostMarkers);
      setLoading(false);
    }
  }, [products, activeBiomarkers, fingerprickOnly, ignoreLondon, maxTests]);
  
  
  const Content: React.FC = () => {
    if (loading) {
      return (
        <Centered>
          <Spin size="large"/>
        </Centered>
      );
    }
    if (activeBiomarkers.length > 0) {
      const Outcome: React.FC = () => {
        if (results.length < 1) {
          return (
            <AntResult
              status="error"
              title="Could not find any options for your criteria"
            >
              <Space direction="vertical" className="desc">
                {fingerprickOnly ? (
                  <div>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> &nbsp;
                    Some tests can't be collected using a fingerprick kit &nbsp;
                    <a onClick={() => setFingerprickOnly(false)}>
                      Include all sample methods &gt;
                    </a>
                  </div>
                ) : (<></>)}
                {ignoreLondon ? (
                  <div>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> &nbsp;
                    Some tests can only be taken at specific clinics &nbsp;
                    <a onClick={() => setIgnoreLondon(false)}>
                      Include these tests &gt;
                    </a>
                  </div>
                ) : (<></>)}
              </Space>
            </AntResult>
          );
        }
        if (cheapest.length > 0) {
          return (
            <AntResult
              status="success"
              title={`${results.length} options found`}
              subTitle="The cheapest options which include your selected biomarkers appear in green below"
            />
          );
        } else {
          return (
            <AntResult
              status="warning"
              title="Couldn't find any options to match all your criteria"
              subTitle={`${results.length} options found`}
            >
              <Space direction="vertical" className="desc">
                {fingerprickOnly ? (
                  <div>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> &nbsp;
                    Some tests can't be collected using a fingerprick kit &nbsp;
                    <a onClick={() => setFingerprickOnly(false)}>
                      Include all sample methods &gt;
                    </a>
                  </div>
                ) : (<></>)}
                {ignoreLondon ? (
                  <div>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> &nbsp;
                    Some tests can only be taken at specific clinics &nbsp;
                    <a onClick={() => setIgnoreLondon(false)}>
                      Include these tests &gt;
                    </a>
                  </div>
                ) : (<></>)}
              </Space>
            </AntResult>
          );
        }
      };
      
      const Cheapest = () => {
        if (cheapest.length > 0) {
          return (
            <>
              <h2>Cheapest option with desired biomarkers:</h2>
              <ResultsTable
                carousel={true}
                biomarkers={activeBiomarkers}
                results={cheapest}
                cheapest={cheapest}
              />
              <Divider/>
            </>
          );
        }
        return (<></>);
      }
      
      const MostBiomarkers = () => {
        return (
          <>
            <h2>Option with most biomarkers in fewest tests:</h2>
            <ResultsTable
              carousel={true}
              biomarkers={activeBiomarkers}
              results={mostMarkers ? [mostMarkers] : []}
              cheapest={cheapest}
            />
            <Divider/>
          </>
        );
      }
      
      return (
        <>
          <Outcome/>
          {/*<Cheapest/>*/}
          {results.length > 0 ? (
            <>
              {/*<MostBiomarkers/>*/}
              {/*<h2>All options:</h2>*/}
              <ResultsTable
                biomarkers={activeBiomarkers}
                results={results}
                cheapest={cheapest}
              />
            </>
          ) : (<></>)}
        </>
      );
    }
    return (
      <>
        <ResultsTable
          biomarkers={activeBiomarkers}
          results={results}
          cheapest={[]}
        />
      </>
    );
  }
  
  return (
    <Layout style={{ maxWidth: '100%' }}>
      <Layout.Header>
        <Container>
          <MainTitle>Medichecks Test Calculator</MainTitle>
        </Container>
      </Layout.Header>
      <Layout.Content
        style={{padding: '10px'}}
      >
        <Container>
          <Row style={{marginBottom: '20px'}}>
            <Col xs={24}>
              <Card>
                <div>
                  This tool helps you find the cheapest Medichecks test(s) which includes
                  all of your desired biomarkers. Matching tests are ordered by biomarker coverage and price.
                </div>
                <div>
                  <Text type="secondary">
                    Only supports blood tests (fingerprick or venous samples), not other sample types (i.e. urine sample tests.)
                  </Text>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={8} lg={6}>
              <Card>
                <Form layout="vertical">
                  <Form.Item label="Search by biomarkers">
                    <Space direction="vertical" style={{width: '100%'}}>
                      <BiomarkerInput onPick={onPickBiomarker}/>
                      <div>
                        <Space direction="vertical" style={{width: '100%'}}>
                          {activeBiomarkers.length > 0 ? activeBiomarkers.map(biomarker => (
                            <Tag 
                              key={biomarker.handle}
                              color="cyan" 
                              closable={true}
                              onClose={() => onRemoveBiomarker(biomarker)}
                            >
                              {biomarker.title}
                            </Tag>
                          )) : (
                            <Alert type="info" message="Start adding biomarkers above to filter available tests."/>
                          )}
                        </Space>
                      </div>
                    </Space>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox checked={fingerprickOnly} onChange={(e) => setFingerprickOnly(e.target.checked)}>
                      Fingerprick tests only
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox checked={ignoreLondon} onChange={(e) => setIgnoreLondon(e.target.checked)}>
                      Ignore tests which require a visit to TDL clinic in London
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="Max tests">
                    <Select value={maxTests} onChange={(v) => setMaxTests(Number(v))}>
                      <Select.Option value={1}>1</Select.Option>
                      <Select.Option value={2}>2</Select.Option>
                      <Select.Option value={3}>3</Select.Option>
                      <Select.Option value={4}>4</Select.Option>
                      <Select.Option value={5}>5</Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col sm={24} md={16} lg={18}>
              <Content/>
            </Col>
          </Row>
        </Container>
      </Layout.Content>
    </Layout>
  );
}

export default App;
